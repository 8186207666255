<template>
  <div class="customer-list">
    <van-search
      @click="onSearch"
      readonly
      input-align="center"
      placeholder="请输入客户的名字"
    />
    <Filter @onChange="onChangeFilter" />
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model:loading="loading"
        v-model:error="error"
        :finished="finished"
        :finished-text="list.length == 0 ? '暂无数据' : '没有更多了'"
        error-text="请求失败，点击重新加载"
        @load="onLoad"
      >
        <div class="user" v-for="user in list" :key="user.id">
          <van-cell
            center
            is-link
            :to="{
              path: 'customer-portrait',
              query: {
                external_userid: user.external_userid
              }
            }"
            :border="false"
          >
            <template #title>
              <div class="user-info">
                <img
                  class="user-avater"
                  :src="
                    user.img
                      ? user.img
                      : 'https://rescdn.qqmail.com/node/wwmng/wwmng/style/images/independent/DefaultAvatar$73ba92b5.png'
                  "
                />
                <div style="padding-left: 10px">
                  <p class="user-name">
                    {{ user.name }}
                    <span
                      class="user-company"
                      :class="user.customer_type == 1 ? '' : 'orange'"
                    >
                      {{
                        user.customer_type == 1 ? '@微信' : `@${user.corp_name}`
                      }}
                    </span>
                  </p>
                  <p class="user-addTime">添加时间：{{ user.add_time }}</p>
                </div>
              </div>
            </template>
          </van-cell>
          <p class="user-history">
            {{ user.other_info.name }}：{{ user.other_info.value }}
          </p>
          <ul class="user-label">
            <van-tag
              v-for="label in user.label"
              :key="label"
              color="#F5F5F5"
              text-color="#5C5C5C"
            >
              {{ label }}
            </van-tag>
          </ul>
          <van-button
            class="user-btn"
            type="primary"
            block
            round
            @click="onOpenChat(user)"
          >
            联系客户
          </van-button>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import {
  List,
  Cell,
  PullRefresh,
  Button,
  Icon,
  Tag,
  NavBar,
  Dialog,
  Search
} from 'vant'
import { reactive, toRefs, ref, watch } from 'vue'
import { useRouter } from 'vue-router'

// import Label from './components/label.vue'
import Filter from './components/filter.vue'

import { getCustomerList } from '@/service/customer.service.js'
import { wechatConfig, wechatAgentConfig } from '@/utils/wechat.js'
import { createCorpGroupChat, openEnterpriseChat } from '@/utils/nwechat.js'

export default {
  name: 'Customer',
  components: {
    [List.name]: List,
    [Cell.name]: Cell,
    [PullRefresh.name]: PullRefresh,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Tag.name]: Tag,
    [NavBar.name]: NavBar,
    [Dialog.name]: Dialog,
    [Search.name]: Search,
    Filter
    // Label
  },
  setup(props) {
    const router = useRouter()
    const state = reactive({
      list: [],
      page: 1,
      page_size: 10,
      loading: false,
      finished: false,
      refreshing: false,
      error: false,
      params: {}
    })
    //加载客户列表
    const onLoad = async () => {
      if (state.refreshing) {
        state.list = []
        state.refreshing = false
        state.page = 1
      }
      if (state.list.length > 0) {
        state.page += 1
      }
      try {
        const res = await getCustomerList(
          Object.assign({}, state.params, {
            page: state.page,
            page_size: state.page_size
          })
        )
        if (res.code == 402) return
        let data = res.data
        state.list = state.list.concat(data.list)
        state.loading = false
        if (data.list.length < state.page_size) {
          state.finished = true
        }
      } catch (error) {
        console.log(error)
      }
    }
    //下拉刷新
    const onRefresh = () => {
      state.refreshing = true
      state.finished = false
      state.loading = true
      onLoad()
    }
    //用户类型
    // const onChangeLabel = (label) => {
    //   state.params = Object.assign({}, state.params, { label_type: label })
    //   onRefresh()
    // }
    //筛选面板
    const onChangeFilter = (params) => {
      state.params = {
        customer_type: params.customerType,
        customer_behavior: params.customerBehavior,
        label: params.label,
        order: params.order,
        order_type: params.orderType
      }
      onRefresh()
    }
    //打开会话
    const onOpenChat = (user) => {
      let obj = {
        externalUserIds: user.external_userid
      }
      if (
        localStorage.getItem('chain_corp_id') &&
        localStorage.getItem('chain_corp_id') != '0'
      ) {
        console.log('createCorpGroupChatparams:', obj)
        createCorpGroupChat(obj)
      } else {
        obj.ChatId = ''
        openEnterpriseChat(obj)
      }
      // wechatAgentConfig((wx) => {
      //   wx.openEnterpriseChat({
      //     externalUserIds: user.external_userid,
      //     chatId: '',
      //     success: function (res) {
      //       console.log(res)
      //     },
      //     fail: function (res) {
      //       if (res.errMsg.indexOf('function not exist') > -1) {
      //         alert('版本过低请升级')
      //       }
      //     }
      //   })
      // })
    }

    const onSearch = () => {
      router.push('/customer-search')
    }

    return {
      ...toRefs(state),
      onLoad,
      onRefresh,
      // onChangeLabel,
      onChangeFilter,
      onOpenChat,
      onSearch
    }
  }
}
</script>

<style lang="less" scoped>
.user {
  background-color: #fff;
  margin: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  &-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  &-avater {
    width: 60px;
    height: 60px;
    border-radius: 4px;
  }
  &-history,
  &-addTime {
    color: #999;
    font-size: 12px;
  }
  &-history,
  &-label {
    padding-bottom: 5px;
  }
  &-label > .van-tag {
    margin-right: 10px;
  }
  &-company {
    color: #07c160;
    &.orange {
      color: #e98839;
    }
  }
  &-btn {
    height: 30px;
    margin: 5px auto;
  }
}
</style>
