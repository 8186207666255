<template>
  <van-dropdown-menu
    active-color="#1989fa"
    ref="dropdownmenu"
    class="customer-filter"
  >
    <van-dropdown-item title="排序" ref="orderRef" @open="initOrders">
      <div class="body">
        <van-radio-group v-model="order">
          <van-cell-group title="排序方式">
            <van-cell
              v-for="item in orders"
              :key="item.value"
              :title="item.name"
              clickable
              @click="order = item.value"
            >
              <template #right-icon>
                <van-radio :name="item.value" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
        <van-radio-group v-model="orderType">
          <van-cell-group title="排序属性">
            <van-cell
              v-for="item in orderTypes"
              :key="item.id"
              :title="item.name"
              clickable
              @click="orderType = item.id"
            >
              <template #right-icon>
                <van-radio :name="item.id" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
      </div>
      <div class="btns">
        <van-button block @click="onResetOrder">重置</van-button>
        <van-button type="primary" block @click="onConfirmOrder">
          确认
        </van-button>
      </div>
    </van-dropdown-item>
    <van-dropdown-item
      title="筛选"
      :title-class="filtersChanged ? 'changed' : ''"
      ref="filterRef"
      @open="initFilters"
      class="van-dropdown-custom-item"
    >
      <div class="body filter">
        <div class="label">客户类型</div>
        <div
          v-for="item in customerTypes"
          :key="item.id"
          class="tag-item"
          :class="customerType.includes(item.id) ? 'active' : ''"
          @click="onTabLabel('customerType', item.id)"
        >
          {{ item.name }}
        </div>
        <!-- <van-grid clickable :column-num="3" :gutter="10">
            <van-grid-item
              v-for="item in customerTypes"
              :key="item.id"
              :text="item.name"
              :class="customerType.includes(item.id) ? 'active' : ''"
              @click="onTabLabel('customerType', item.id)"
            />
          </van-grid> -->
        <div
          v-if="customerBehaviors && customerBehaviors.length > 0"
          class="label"
        >
          客户行为
        </div>
        <div
          v-for="item in customerBehaviors"
          :key="item.id"
          class="tag-item"
          :class="customerBehavior.includes(item.id) ? 'active' : ''"
          @click="onTabLabel('customerBehavior', item.id)"
        >
          {{ item.name }}
        </div>
        <!-- <van-grid clickable :column-num="3" :gutter="10">
            <van-grid-item
              v-for="item in customerBehaviors"
              :key="item.id"
              :text="item.name"
              :class="customerBehavior.includes(item.id) ? 'active' : ''"
              @click="onTabLabel('customerBehavior', item.id)"
            />
          </van-grid> -->
        <div v-for="(item, index) in labels" :key="item.group_name + index">
          <div class="label">{{ item.group_name }}</div>
          <div
            v-for="item in item.group_list"
            :key="item.id"
            class="tag-item"
            :class="label.includes(item.id) ? 'active' : ''"
            @click="onTabLabel('label', item.id)"
          >
            {{ item.label_name }}
          </div>
          <!-- <van-grid clickable :column-num="3" :gutter="10">
              <van-grid-item
                v-for="item in item.group_list"
                :key="item.id"
                :text="item.label_name"
                :class="label.includes(item.id) ? 'active' : ''"
                @click="onTabLabel('label', item.id)"
              />
            </van-grid> -->
        </div>
      </div>
      <div class="filter-btns">
        <van-button block @click="onResetFilter">重置</van-button>
        <van-button type="primary" block @click="onConfirmFilter">
          确认
        </van-button>
      </div>
    </van-dropdown-item>
  </van-dropdown-menu>
</template>

<script>
import {
  DropdownMenu,
  DropdownItem,
  Switch,
  Cell,
  CellGroup,
  Radio,
  RadioGroup,
  Button,
  Grid,
  GridItem
} from 'vant'

import { ref, reactive, toRefs, toRaw, computed } from 'vue'

import {
  getSortProperties,
  getFilterLabel
} from '@/service/customer.service.js'

export default {
  name: 'Filter',
  components: {
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Switch.name]: Switch,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    [Button.name]: Button,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem
  },
  setup(props, { emit }) {
    const orderRef = ref(null)
    const filterRef = ref(null)
    const state = reactive({
      order: null,
      orderType: null,
      customerType: [],
      customerBehavior: [],
      label: [],
      orders: [],
      orderTypes: [],
      customerTypes: [],
      customerBehaviors: [],
      labels: []
    })

    //排序-初始化
    const initOrders = async (reset) => {
      const { data } = await getSortProperties()
      state.orders = data.order
      state.orderTypes = data.order_type
      if (!state.order || !state.orderType || reset) {
        state.order = data.order.find((item) => item.default).value
        state.orderType = data.order_type.find((item) => item.default).id
      }
    }
    //排序-确定
    const onConfirmOrder = () => {
      orderRef.value.toggle()
      // state.customerBehaviors
      // state.customerTypes = data.customer_type
      console.log(
        'state.customerBehaviors====>排序确定',
        state.customerBehaviors,
        toRaw(state.customerType),
        toRaw(state.customerBehavior),
        toRaw(state.label)
      )
      emit('onChange', {
        order: state.order,
        orderType: state.orderType,
        customerType: toRaw(state.customerType),
        customerBehavior: toRaw(state.customerBehavior),
        label: toRaw(state.label)
      })
    }
    //排序-重置
    const onResetOrder = () => {
      initOrders('reset')
    }
    //筛选-初始化
    const initFilters = async () => {
      const { data } = await getFilterLabel()
      state.customerTypes = data.customer_type
      state.customerBehaviors = data.customer_behavior
      state.labels = data.label
      console.log('state.$refs.dropdownmenu')
      state.$refs.dropdownmenu.toggle()
    }
    //筛选-确定
    const onConfirmFilter = () => {
      filterRef.value.toggle()
      emit('onChange', {
        order: state.order,
        orderType: state.orderType,
        customerType: toRaw(state.customerType),
        customerBehavior: toRaw(state.customerBehavior),
        label: toRaw(state.label)
      })
    }
    //筛选-重置
    const onResetFilter = () => {
      console.log('000onResetFilter')
      state.customerType = []
      state.customerBehavior = []
      state.label = []
      initFilters()
    }
    //筛选-点击选择
    const onTabLabel = (label, id) => {
      if (state[label].includes(id)) {
        state[label] = state[label].filter((item) => item != id)
      } else {
        state[label].push(id)
      }
    }
    //是否选择
    const filtersChanged = computed(() => {
      if (
        state.customerType.length > 0 ||
        state.customerBehavior.length > 0 ||
        state.label.length
      ) {
        return true
      }
      return false
    })

    return {
      ...toRefs(state),
      onConfirmOrder,
      onResetOrder,
      onConfirmFilter,
      onResetFilter,
      onTabLabel,
      orderRef,
      filterRef,
      initFilters,
      initOrders,
      filtersChanged
    }
  }
}
</script>

<style lang="less">
.customer-filter {
  .label {
    padding: 10px 12px;
    font-size: 12px;
    font-weight: 500;
  }
  .van-popup.van-popup--top.van-dropdown-item__content {
    display: flex;
    flex-direction: column;
  }
  .body {
    padding-bottom: 50px;
    overflow-y: auto;
    &.filter {
      padding-left: 12px;
      padding-right: 4px;
      .label {
        padding: 10px 0;
      }
    }
  }
  .btns {
    height: 44px;
    display: flex;
  }
  .filter-btns {
    display: flex;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  .active > .van-grid-item__content > .van-grid-item__text {
    color: #1989fa;
  }
  .changed {
    color: #1989fa;
  }
  .tag-item {
    display: inline-block;
    text-align: center;
    min-width: 80px;
    height: 26px;
    line-height: 26px;
    margin: 0 8px 8px 0;
    padding: 0 8px;
    border-radius: 2px;
    font-size: 12px;
    color: #6f6f6f;
    background: #f5f5f5;
    &.active {
      color: #fff;
      background: #1989fa;
    }
  }
}

.label {
  color: #000;
}
</style>
